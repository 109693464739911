import { useTranslation } from "react-i18next";

export default () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="text-gray-200 bg-green-700 px-4 py-5 md:px-8">
      <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0">
        <li className=" hover:text-gray-800">
          <a href={"#list"}>{t("Navbar.About_us")}</a>
        </li>
        <li className=" hover:text-gray-800">
          <a href={"#adventures"}>{t("Navbar.Services")}</a>
        </li>
        <li className=" hover:text-gray-800">
          <a href={"#quality"}>{t("Navbar.Quality")}</a>
        </li>
        <li className=" hover:text-gray-800">
          <a href={"#contacts"}>{t("Navbar.Contact_us")}</a>
        </li>
        {/* <li className=" hover:text-gray-800">
          <a href={"#request"}>{t("Navbar.Request")}</a>
        </li> */}
      </ul>
      <div className="mt-8 items-center justify-between sm:flex">
        <div className="mt-4 sm:mt-0">
        <ul className="space-y-2">
          <li className="flex gap-2">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
        +994 51 783 00 00</li>
          {/* <li className="flex gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
            Potsdamer Straße 92, 10785 Berlin</li> */}
          <li className="flex gap-2">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
        rustam.gurbanov@hermes-trading.az</li>
          <li className="mt-4">&copy; {t("Footer.Rights")}</li>
        </ul>
        </div>
      </div>
    </footer>
  );
};