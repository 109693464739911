const a = require("./assets/yashik.jpg");
const b = require("./assets/korobka.jpg");
const c = require("./assets/meshok.PNG");

const Fasovka = () => {

  const team = [
      {
          avatar: a,
          name: "Ящик"
      },
      {
          avatar: b,
          name: "Коробка"
      },
      {
          avatar: c,
          name: "Мешок"
      },
  ]

  return (
      <section className="py-14" id="adventures">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8">
              <div className="max-w-xl">
                  <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                  Фасовка товаров

                  </h3>
                  <p className="text-gray-600 mt-3">
                  Тип упаковки и размер порции мы выбираем таким образом, чтобы обеспечить
удовлетворение потребностей потребителя, максимальную экономичность и
удобство при транспортировке.

                  </p>
                  <p className="text-gray-600 mt-3 text-lg font-bold">Примеры упаковок (Вес самой упаковки от 4 до 25 кг):</p>
              </div>
              <div className="mt-12 text-center">
                  <ul className="text-center items-center justify-center grid gap-8 sm:grid-cols-2 md:grid-cols-3">
                      {
                          team.map((item, idx) => (
                              <li key={idx} className="h-60 flex flex-col justify-center shadow-md rounded-full p-5">
                                  <div className="flex justify-center">
                                      <img
                                          src={item.avatar}
                                          className="w-40 h-40"
                                          alt=""
                                      />
                                  </div>
                                  <div>
                                      <h4 className="text-gray-500 text-sm sm:text-md font-semibold">{item.name}</h4>
                            
                                      
                                  </div>
                              </li>
                          ))
                      }
                  </ul>
              </div>
          </div>
      </section>
  )
}

export default Fasovka;
