import { useState } from "react";
import { useTranslation } from "react-i18next";
const Navbar = () => {
  const logo = require("./assets/hermes_logo.png"); 
  const { t, i18n } = useTranslation();
  // const handleClick = (lang) => {
  //   i18n.changeLanguage(lang);
  //   console.log(lang);
  // };

  const [state, setState] = useState(false);

  return (
    <header className="w-full border-b md:border-0 md:static">
      <nav className="items-center pt-5 px-4 mx-auto max-w-screen-xl sm:px-8 md:flex md:space-x-6">
        <div className="flex justify-between items-center">
          <a href="/">
            <img
              src={logo}
              width={120}
              height={120}
              alt="HERMES"
              className="mb-3 sm:mb-0"
            />
          </a>
          <button
            className="text-gray-600 outline-none md:-ml-28 lg:hidden"
            onClick={() => setState(!state)}
          >
            {state ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
        <ul
          onClick={() => setState(!state)}
          className={`flex-1 justify-between mt-12 lg:flex md:mt-0 ${
            state ? "" : "hidden"
          }`}
        >
          <li className="order-2 pb-5 md:pb-0">
            <a
              href="#contacts"
              className="py-3 px-6  rounded-md shadow-md text-white text-center bg-green-600 focus:shadow-none block"
            >
              {t("Navbar.Request")}
            </a>
          </li>

          <div className="order-1 flex-1 justify-center items-center space-y-5 md:flex md:space-x-6 md:space-y-0">
            <li className="text-gray-500 hover:text-green-600">
              <a href="#list">{t("Navbar.About_us")}</a>
            </li>
            <li className="text-gray-500 hover:text-green-600">
              <a href="#adventures">{t("Navbar.Services")}</a>
            </li>
            <li className="text-gray-500 hover:text-green-600">
              <a href="#quality">{t("Navbar.Quality")}</a>
            </li>
            <li className="text-gray-500 hover:text-green-600">
              <a href="#contacts">{t("Navbar.Contact_us")}</a>
            </li>
          </div>
          {/* <img
            className="mt-8 md:mt-2 md:ml-3 ml-2 cursor-pointer"
            src="https://cdn.countryflags.com/thumbs/azerbaijan/flag-round-250.png"
            width={40}
            height={30}
            alt="aze"
            onClick={() => handleClick("az")}
          />
          <img
            className="mt-4 md:mt-2 md:ml-3 ml-2 cursor-pointer"
            src="https://user-images.githubusercontent.com/49064106/229375189-201d3889-af7c-444a-b649-5f888661036e.png"
            width={40}
            height={40}
            alt="rus"
            onClick={() => handleClick("ru")}
          /> */}
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
