const apple = require("./assets/apple.jpg");
const granat = require("./assets/granat.jpg");
const xurma = require("./assets/xurma.jpg");
const nektarin = require("./assets/nektarin.jpg");
const grape = require("./assets/grapes.jpg");
const persik = require("./assets/persik.jpg");


const List = () => {

  const team = [
      {
          avatar: granat,
          name: "Гранат",
          title: "Product designer",
          desc: "Это теплолюбивый фрукт, лучше всего он растет на Ближнем востоке, Кавказе, в Африке и Азии. Основной сезон созревания — с сентября по ноябрь, но благодаря длительному сроку хранения гранат можно купить круглый год..",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
      {
          avatar: xurma,
          name: "Хурма",
          title: "Software engineer",
          desc: "Созревшая хурма имеет мягкий вкус, похожий на тыквенный, но с добавленной фруктовой сладостью. Незрелые плоды менее сладкие, слегка терпкие, с хрустящей текстурой.",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
      {
          avatar: nektarin,
          name: "Нектарин",
          title: "Full stack engineer",
          desc: "Это прекрасный продукт, богатый клетчаткой, витаминами и минералами, в нем мало калорий",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
      {
          avatar: apple,
          name: "Яблоко",
          title: "Head of designers",
          desc: "Xороший продукт для диеты, богатый минералами, витаминами и клетчаткой, а также имеющий мало калорий",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
      {
          avatar: grape,
          name: "Виноград",
          title: "Product designer",
          desc: "Ягоды винограда имеют профилактические свойства при рисках развития сахарного диабета",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
      {
          avatar: persik,
          name: "Персик",
          title: "Product manager",
          desc: "Hастоящий символ лета, найти их на рыночных прилавках можно с мая по сентябрь. .",
          linkedin: "javascript:void(0)",
          twitter: "javascript:void(0)",
          github: "javascript:void(0)"
      },
  ]

  return (
      <section className="py-14" id="list">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8">
              <div className="max-w-xl">
                  <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                  Ассортимент
                  </h3>
                  <p className="text-gray-600 mt-3">
                  У нас вы всегда найдете большой ассортимент овощей и фруктов
                  </p>
              </div>
              <div className="mt-12">
                  <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
                      {
                          team.map((item, idx) => (
                              <li key={idx}>
                                  <div className="w-full h-60 sm:h-52 md:h-56">
                                      <img
                                          src={item.avatar}
                                          className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                          alt=""
                                      />
                                  </div>
                                  <div className="mt-4">
                                      <h4 className="text-lg text-gray-700 font-semibold">{item.name}</h4>
                                      
                                      <p className="text-gray-600 mt-2 text-sm">{item.desc}</p>
                                      
                                  </div>
                              </li>
                          ))
                      }
                  </ul>
              </div>
          </div>
      </section>
  )
}

export default List;
