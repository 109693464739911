const a = require("./assets/11.PNG");
const b = require("./assets/22.PNG");
const c = require("./assets/333.PNG");
const d = require("./assets/444.PNG");
const e = require("./assets/555.PNG");
const f = require("./assets/666.PNG");


const Adventures = () => {

  const team = [
      {
          avatar: a,
          name: "Высокое качество продукции"
      },
      {
          avatar: b,
          name: "Гарантия свежести"
      },
      {
          avatar: c,
          name: "Полный пакет документов",
          title: "Full stack engineer"
      },
      {
          avatar: d,
          name: "Прямые поставки",
          title: "Head of designers"
      },
      {
          avatar: e,
          name: "Приемлемые цены",
          title: "Product designer"
      },
      {
          avatar: f,
          name: "Четкое соблюдение условий договора"
      },
  ]

  return (
      <section className="py-14" id="adventures">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8">
              <div className="max-w-xl">
                  <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                  Преимущества сотрудничества

                  </h3>
                  <p className="text-gray-600 mt-3">
                  С целью поддержания партнерских отношений с вашей компанией
готовы обсудить индивидуальные условия работы.
                  </p>
              </div>
              <div className="mt-12 text-center">
                  <ul className="text-center items-center justify-center grid gap-8 grid-cols-2 sm:grid-cols-2 md:grid-cols-3">
                      {
                          team.map((item, idx) => (
                              <li key={idx} className="h-60 flex flex-col justify-center shadow-md rounded-xl p-5">
                                  <div className="flex justify-center">
                                      <img
                                          src={item.avatar}
                                          className="w-12 h-13"
                                          alt=""
                                      />
                                  </div>
                                  <div>
                                      <h4 className="text-gray-500 text-sm sm:text-md font-semibold">{item.name}</h4>
                            
                                      
                                  </div>
                              </li>
                          ))
                      }
                  </ul>
              </div>
          </div>
      </section>
  )
}

export default Adventures;
