import React, { Suspense } from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import About from "./About";
import ScrollToTopButton from "./ScrollToTopButton";
import CTA from "./CTA";
import Features from "./Features";
import Quality from "./Quality";
import Request from "./Request";
import Contacts from "./Contacts";
import Footer from "./Footer";
import List from "./List";
import Adventures from "./Adventures";
import Fasovka from "./Fasovka";
import Become from "./Become";
import './App.css';

function App() {
  return (
    <Suspense fallback={<Test />}>
      <Navbar />
      <Header />
      <CTA />
      <List/>
      <Quality />
      <Adventures/>
      <Fasovka/>
      <About />
      {/* <Become/> */}
      {/* <Features /> */}
      
      {/* <Request /> */}
      <Contacts />
      <Footer />
      <ScrollToTopButton />
    </Suspense>
  );
}

export default App;

export const Test = () => {
  return (
    <div>
      <p>... loading</p>
    </div>
  );
};