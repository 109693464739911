import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./App.css";

const Header = () => {
  const { t, i18n } = useTranslation();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    console.log(lang);
  };

  const [state, setState] = useState(false);

  return (
    <>
      <div
        className="relative mt-5 pt-16 pb-16 flex content-center items-center justify-center"
        style={{
          minHeight: "75vh",
        }}
      >
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage:
              "url('https://kidpassage.com/images/publications/fruktyi-v-gruzii/1838_Frukty-gruzii-gallery311673878.jpg')",
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap p-10">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="text-center space-y-4 ">
                <h1 className="text-gray-100 font-bold text-4xl md:text-6xl sm:leading-loose">
                  {t("Header.Title1")}
                </h1>
                <p className="text-2xl md:text-4xl text-green-200 sm:leading-loose font-semibold">
                  {" "}
                  {t("Header.Title2")}
                </p>
                <p className="text-gray-200 max-w-xl mx-auto">
                  "{t("Header.Text")}"
                </p>
              </div>
              <div className="mt-12 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                <a
                  href="#adventures"
                  className="px-10 py-3.5 w-full bg-green-600 text-white text-center rounded-md shadow-md block sm:w-auto"
                >
                  {t("Header.Buy now")}
                </a>
                <a
                  href="#contacts"
                  className="px-10 py-3.5 w-full text-gray-100 text-center border rounded-md duration-300 hover:text-green-600 hover:shadow block sm:w-auto"
                >
                  {t("Header.Contact us")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
